import React from 'react';
import Navbar from './Navbar';

export default function Header() {
  return (
    <header className='box-border m-2 p-2 flex flex-col lg:flex-row justify-between items-center'>      
      <a href="/" className='w-full lg:w-1/3 flex justify-center lg:justify-start items-center mb-4 lg:mb-0'>
        <h1 className='text-2xl lg:text-4xl font-extrabold ml-2'>Apartment-Matrix</h1>
      </a>
      {/* <div className='w-full lg:w-1/3 flex justify-center mb-4 lg:mb-0'>
        <a href='https://blog.codok.org/entry/category/apartment-codok/' className='text-lg lg:text-2xl w-full'>
          <button 
            type="submit" 
            className='w-full outline px-3 py-2 md:px-5 md:py-2 bg-violet-600 text-white rounded-md hover:bg-blue-600 text-sm md:text-base'
          >
            Apartment-Matrix 사용설명서
          </button>
        </a>
      </div> */}
      {/* <div className='w-full md:w-1/3 flex justify-center md:justify-end text-base md:text-xl'>
        <p className='text-center md:text-right whitespace-nowrap'>문의사항 humanist96@gmail.com</p>
      </div> */}
      <Navbar />
    </header>
  );
}