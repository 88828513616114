// src/components/Navbar.jsx
import React from 'react';

const Navbar = () => {
  return (
    <nav className='flex flex-col lg:flex-row justify-between items-center'>
      <a href="/" className='text-lg lg:text-2xl p-2'>Home</a>
      {/* <a href="/about" className='text-lg lg:text-2xl p-2'>About</a> */}
      <a href="/area" className='text-lg lg:text-2xl p-2'>SearchByArea</a>
      {/* <a href="/pilot" className='text-lg lg:text-2xl p-2'>Pilot</a> */}
      <a href="/contact" className='text-lg lg:text-2xl p-2'>문의사항</a>
    </nav>
  );
};

export default Navbar;
